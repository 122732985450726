import queryString from 'query-string';

import {
  incrementLoadingProgress,
  setLoadingFinished,
  addLoadingError
} from './store/actions/loading';

import {
  loadWiresSourceData,
  loadRisksSourceData,
  loadMapboxSettings
} from './store/actions/map';

const PROGRESS_INCREMENT = 33;

// Loads settings file, as well as risks and wires data files
// Uses content of these files to insert it into app state tree
export default function configureMapData(store, configureRisksState) {
  const { dispatch } = store;
  const { token } = queryString.parse(window.location.search);

  const BUCKET_URL = 'https://s3.eu-north-1.amazonaws.com/app.spacept.com';
  const SETTINGS_URL = `${BUCKET_URL}/${token}/settings.json`;
  const WIRES_JSON_URL = `${BUCKET_URL}/${token}/wires.json`;
  const RISKS_JSON_URL = `${BUCKET_URL}/${token}/risks.json`;
  // we fetch all the files simultaneously, but only when all are done,
  // we can finalize loading process

  const urls = [SETTINGS_URL, WIRES_JSON_URL, RISKS_JSON_URL];
  const requests = urls.map(url => {
    return fetch(url)
      .then(handleFetchErrors)
      .then(response => response.json())
      .then(function (json) {
        dispatch(incrementLoadingProgress(PROGRESS_INCREMENT));
        return json;
      })
      .catch(function (error) {
        dispatch(addLoadingError(`${error.message}: ${url}`));
        throw(error); // prevents Promise.all() from resolving
      });
  });

  Promise.all(requests).then(function (jsonData) {
    const { mapboxToken, mapStyleUrl, tilesSourceUrl } = jsonData[0];
    const wiresData = jsonData[1];
    const risksData = jsonData[2];

    dispatch(loadMapboxSettings(mapboxToken, mapStyleUrl, tilesSourceUrl));
    dispatch(loadWiresSourceData(wiresData));
    dispatch(loadRisksSourceData(risksData));

    dispatch(setLoadingFinished());

    // this should happen only after successful loading done
    configureRisksState(store);
  }).catch(error => console.error(error));
}

// -- helpers

// Throws an exception if resource is unavailable (404, for example)
function handleFetchErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }

  return response;
}
