import React from 'react';
import { connect } from 'react-redux';
import MapGL, { Source, Layer, NavigationControl } from 'react-map-gl';
import PitchModeToggle from './PitchModeToggle';
import ZeroHeightModeToggle from './ZeroHeightModeToggle';
import { updateOperativeCoordinates, updateOperativeRiskLevel } from '../store/actions/operative';
import { updateViewport, moveToCoordinates } from '../store/actions/viewport';
import { EXTRUSION_LAYER, EXTRUSION_LAYER_DEFAULT_HEIGHT } from '../store/layerTypes';
import WebMercatorViewport from 'viewport-mercator-project';
import { throttle } from 'lodash';
import { parseUrlParams } from '../configureUrlManager';

const RISKS_SOURCE_ID = 'RISKS_SOURCE_ID';
const RISKS_LAYER_ID = 'RISKS_LAYER_ID';
const RISKS_LAYER_MAX_OPACITY_COEF = 0.8; // related to opacity slider logic

// map modes satellite layer opacity levels
const INVISIBLE = 0;
const VISIBLE = 1;

// params we need to set up initial viewport
// if any of these is missing, we use default box bound algorithm
// to set up the inital viewport, so users see area that covers all risks
const REQUIRED_INITIAL_VIEWPORT_PARAMS = ['longitude', 'latitude', 'bearing', 'pitch', 'zoom'];

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.urlParams = parseUrlParams();
    this.throttledHandleOnHover = throttle(this.handleOnHover, 300);
  }

  handleOnHover = (pointerEvent) => {
    const { dispatch } = this.props;
    const map = this.mapRef.current;

    let riskLevel = 0;

    if (map.getMap().getLayer(RISKS_LAYER_ID)) {
      let feature = map.queryRenderedFeatures(pointerEvent.point, {
        layers: [RISKS_LAYER_ID]
      })[0];

      if (feature) {
        riskLevel = feature.properties.risk;
      }
    }

    dispatch(updateOperativeRiskLevel(riskLevel));
    dispatch(updateOperativeCoordinates(pointerEvent));
  }

  setInitialViewport = () => {
    const { dispatch, viewport, risksData } = this.props;

    if (requiredParamsPresented(REQUIRED_INITIAL_VIEWPORT_PARAMS, this.urlParams)) {
      dispatch(updateViewport(this.urlParams));
    }
    else {
      const flattenCoordinatesList = risksData.features.map((feature) => (feature.geometry.coordinates[0])).flat();
      const { minLng, minLat, maxLng, maxLat } = findBoundCoordinates(flattenCoordinatesList);

      // construct a viewport instance from the current state
      const mercatorViewport = new WebMercatorViewport(viewport);
      const { longitude, latitude, zoom } = mercatorViewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
        padding: 40
      });

      dispatch(moveToCoordinates(longitude, latitude, zoom));
    }
  }

  render() {
    const {
      mapStyle,
      mapboxToken,
      tilesSourceUrl,
      dispatch,
      viewport,
      risksData,
      wiresData,
      mode,
      opacity,
      wiresLayer,
      tilesLayer,
      riskLevel,
      zero_height
    } = this.props;

    const risksLayer = EXTRUSION_LAYER;
    const risksLayerOpacity = opacity * RISKS_LAYER_MAX_OPACITY_COEF;
    const risksDataFiltered = filterRisks(risksData, riskLevel);

    // set powerlines and risks layers opacity
    wiresLayer['paint'] = {
      ...wiresLayer['paint'],
      'line-opacity': opacity
    };
    risksLayer['paint'] = {
      ...risksLayer['paint'],
      'fill-extrusion-opacity': risksLayerOpacity
    };

    if (zero_height) {
      risksLayer['paint'] = {
        ...risksLayer['paint'],
        'fill-extrusion-height': 0
      };
    }
    else {
      risksLayer['paint'] = {
        ...risksLayer['paint'],
        'fill-extrusion-height': EXTRUSION_LAYER_DEFAULT_HEIGHT
      };
    }

    // set satellite layer opacity according to the current mode
    tilesLayer['paint'] = {
      'raster-opacity': getSatelliteOpacity(mode)
    };

    return (
      <MapGL
        {...viewport}
        ref={this.mapRef}
        width="100%"
        height="100%"
        mapStyle={mapStyle}
        onViewportChange={(viewport) => dispatch(updateViewport(viewport))}
        onHover={this.throttledHandleOnHover}
        mapboxApiAccessToken={mapboxToken}
        onLoad={this.setInitialViewport}
      >

        <div className="map-controls">
          <NavigationControl />
          <br />
          <PitchModeToggle />
          <br />
          <ZeroHeightModeToggle />
        </div>

        <Source type="raster" url={tilesSourceUrl}>
          <Layer {...tilesLayer} />
        </Source>

        <Source type="geojson" data={wiresData}>
          <Layer {...wiresLayer} />
        </Source>

        <Source type="geojson" id={RISKS_SOURCE_ID} data={risksDataFiltered} generateId={true}>
          <Layer id={RISKS_LAYER_ID} {...risksLayer} />
        </Source>

      </MapGL>
    );
  }
}

const mapStateToMapProps = (state) => ({
  viewport: state.viewport,
  mode: state.mode,
  opacity: state.opacity,
  risksData: state.map.sources.risks,
  wiresData: state.map.sources.wires,
  tilesLayer: state.map.layers.tiles,
  wiresLayer: state.map.layers.wires,
  riskLevel: state.riskLevel,
  mapStyle: state.map.mapStyle,
  mapboxToken: state.map.mapboxToken,
  tilesSourceUrl: state.map.tilesSourceUrl,
  zero_height: state.zero_height
});

export default connect(mapStateToMapProps)(React.memo(Map));

// -- helpers

function filterRisks(data, level) {
  const risksFiltered = data.features ? data.features.filter(function (r) {
    return r.properties.risk >= level;
  }) : [];
  return { ...data, features: risksFiltered };
}

function getSatelliteOpacity(mode) {
  switch (mode) {
    case 1:
      return INVISIBLE;
    default:
      return VISIBLE;
  }
}

function findBoundCoordinates(allCoordinates) {
  let minLng = 360, minLat = 90;
  let maxLng = 0, maxLat = -90;

  // find min and max coordinates by checking all the features
  allCoordinates.forEach(([lng, lat]) => {
    if (minLng > lng) {
      minLng = lng;
    }

    if (minLat > lat) {
      minLat = lat;
    }

    if (maxLng < lng) {
      maxLng = lng;
    }

    if (maxLat < lat) {
      maxLat = lat;
    }
  });

  return { minLng, minLat, maxLng, maxLat };
}

function requiredParamsPresented(requiredKeys, params = {}) {
  const givenKeys = Object.keys(params);
  const presentKeys = givenKeys.filter(key => requiredKeys.indexOf(key) >= 0);

  return requiredKeys.length === presentKeys.length;
}
