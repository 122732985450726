import React from 'react';
import { connect } from 'react-redux';
import Logo from './Logo';

const HIDE_TIMEOUT = 1000; // should match transition-duration in styles/components/loading_screen.sass

class LoadingScreen extends React.Component {
  state = {
    isBlurred: true,
    isHidden: false
  }

  handleOnClick = () => {
    const { finished } = this.props.loading;

    if (finished) {
      this.setState({
        ...this.state,
        isBlurred: false
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          isHidden: true
        })
      }, HIDE_TIMEOUT);
    }
  }

  renderErrors(errors) {
    if (errors.length === 0) {
      return null;
    }

    return (
      <ul className="loading_screen-errors">
        <li className="loading_screen-error"><b>Loading errors</b></li>
        {errors.map(function(error, index) {
          return (
            <li key={`error-${index}`} className="loading_screen-error">
              {error}
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const { isHidden, isBlurred } = this.state;
    const { finished, progress, errors } = this.props.loading;

    if (isHidden) {
      return (<div></div>);
    }

    return (
      // <div className={'loading_screen ' + (isBlurred ? 'is-blurred' : '')} onClick={this.handleOnClick}>
      <div className={'loading_screen ' + (isBlurred ? 'is-blurred' : '')} onClick={this.handleOnClick}>
        <div className="loading_screen-message">
          <Logo />

          <div className="loading_screen-message_text is-progress">
            Loading: {progress}%
          </div>

          {this.renderErrors(errors)}

          <div className="loading_screen-message_text">
            {finished ? 'Please, click to proceed...' : null}
          </div>
        </div>
        <div className="loading_screen-spacer"></div>
      </div>
    );
  }
}

const mapStateToLoadingScreenProps = (state) => ({
  loading: state.loading
});

export default connect(mapStateToLoadingScreenProps)(LoadingScreen);
