import {
  LOAD_WIRES_SOURCE_DATA,
  LOAD_RISKS_SOURCE_DATA,
  LOAD_MAPBOX_SETTINGS,
  ADD_HIGHLIGHT_TO_RISK,
  REMOVE_HIGHLIGHT_FROM_RISK,
  ADD_SELECTION_TO_RISK,
  REMOVE_SELECTION_FROM_RISK
} from '../actionTypes';

const initialState = {
  mapboxToken: "pk.eyJ1IjoiYXJzaGFrdSIsImEiOiJjanRnejJvaWkwODh0M3lvNjNqdnU1bDV1In0.3f36RM3MisT_guxB-3ogLw",
  mapStyle: "mapbox://styles/mapbox/streets-v11",
  tilesSourceUrl: "mapbox://arshaku.2he42am5",
  sources: {
    wires: {
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:EPSG::4283"
        }
      },
      features: [],
      type: "FeatureCollection"
    },
    risks: {
      features: [],
      type: "FeatureCollection"
    }
  },
  // initial set of layers: wires and tileset with a satellite image
  layers: {
    wires: {
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#00f',
        'line-width': 2,
        'line-opacity': 1
      }
    },
    tiles: {
      type: 'raster',
      paint: {
        'raster-opacity': 1
      }
    }
  }
};

export default function map(state = initialState, action) {
  const risks = state.sources.risks.features;

  switch(action.type) {
    case LOAD_WIRES_SOURCE_DATA:
      return {
        ...state,
        sources: {
          ...state.sources,
          wires: action.payload
        }
      };

    case LOAD_RISKS_SOURCE_DATA:
      return {
        ...state,
        sources: {
          ...state.sources,
          risks: action.payload
        }
      };

    case LOAD_MAPBOX_SETTINGS:
      const { mapboxToken, mapStyle, tilesSourceUrl } = action.payload;

      return {
        ...state,
        mapboxToken,
        mapStyle,
        tilesSourceUrl
      };

    case ADD_HIGHLIGHT_TO_RISK:
      const highlightedRisks = risks.map((risk) => {
        if (risk.id === action.payload.id.toString()) {
          risk.properties.highlighted = true;
        }

        return risk;
      });

      return {
        ...state,
        sources: {
          ...state.sources,
          risks: {
            type: 'FeatureCollection',
            features: highlightedRisks
          }
        }
      };

    case REMOVE_HIGHLIGHT_FROM_RISK:
      const noHighlightRisks = risks.map((risk) => {
        if (risk.id === action.payload.id.toString()) {
          risk.properties.highlighted = false;
        }

        return risk;
      });

      return {
        ...state,
        sources: {
          ...state.sources,
          risks: {
            type: 'FeatureCollection',
            features: noHighlightRisks
          }
        }
      };

    case ADD_SELECTION_TO_RISK:
      const selectedRisks = risks.map((risk) => {
        if (risk.id === action.payload.id.toString()) {
          risk.properties.selected = true;
        }

        return risk;
      });

      return {
        ...state,
        sources: {
          ...state.sources,
          risks: {
            type: 'FeatureCollection',
            features: selectedRisks
          }
        }
      };

    case REMOVE_SELECTION_FROM_RISK:
      const noSelectedRisks = risks.map((risk) => {
        if (risk.id.toString() === action.payload.id.toString()) {
          risk.properties.selected = false;
        }

        return risk;
      });

      return {
        ...state,
        sources: {
          ...state.sources,
          risks: {
            type: 'FeatureCollection',
            features: noSelectedRisks
          }
        }
      };

    default:
      return state;
  }
}
