import React from 'react';
import { connect } from 'react-redux';

import LoadingScreen from './LoadingScreen';
import ControlPanel from './ControlPanel';
import InfoSection from './InfoSection';
import Map from './Map';

class App extends React.Component {
  render() {
    // const { finished } = this.props.loading;

    // if (!finished) {
    //   return (
    //     <main>
    //       <LoadingScreen />
    //     </main>
    //   );
    // }

    return (
      <main>
        <LoadingScreen />
        <div id="app">
          <ControlPanel />
          <InfoSection />
          <Map />
        </div>
      </main>
    );
  }
}

const mapStateToAppProps = (state) => ({
  loading: state.loading
});

export default connect(mapStateToAppProps)(App);
